import React from 'react';
import './App.css';
// import Galexy from './pages/galexy/index';
// import CuteStar from './pages/cuteStar';
import Island from './pages/island';

function App() {
  return (
    <div className="App">
    <Island />
    </div>
  );
}

export default App;
